import { AppManifestBuilder } from '@wix/yoshi-flow-editor';

import { openSearchResultsWidgetSettingsPanel } from '../searchResultsWidgetSettings';
import { ControllerType, EditorAppContext, WixCodeRole } from '../types';
import { Spec } from '../../lib/specs';

export function overrideSearchBoxGfpp(
  appContext: EditorAppContext,
  appManifestBuilder: AppManifestBuilder,
): AppManifestBuilder {
  const { experiments, translate, reportError } = appContext;

  if (!experiments.enabled(Spec.ManageSearchResultsGfpp)) {
    return appManifestBuilder;
  }

  return appManifestBuilder.configureController(
    ControllerType.SearchApp,
    (controllerBuilder) => {
      controllerBuilder.configureConnectedComponents(
        WixCodeRole.SearchBox,
        (connectedComponentsBuilder) => {
          connectedComponentsBuilder
            .gfpp()
            .set('mainAction1', {
              label: translate('searchBox.gfpp.manageSearchResults'),
              async onClick() {
                try {
                  await openSearchResultsWidgetSettingsPanel(appContext);
                } catch (e) {
                  reportError(e);
                }
              },
            })
            // Note that standard config is to have "Settings" as first main action (mainAction1).
            // With this override we swap the position of this button to be second (mainAction2).
            .set('mainAction2', {
              behavior: 'DEFAULT1',
            });
        },
      );
    },
  );
}
