import {
  EditorReadyFn,
  GetAppManifestFn,
  HandleActionFn,
} from '@wix/yoshi-flow-editor';
import { EventType } from '@wix/editor-platform-sdk-types';

import { ActionType, ControllerType, EditorAppContext } from './types';
import { Interaction } from './constants/interaction';
import { doFirstInstall } from './doFirstInstall';
import { getAllSearchBoxes } from './searchBox';
import { reconnectSearchBoxes } from './reconnectSearchBoxes';
import { patchInputFontProperty } from './patchInputFontProperty';
import { fixAppControllerLayout } from './fixAppControllerLayout';
import { onRemoveApp } from './onRemoveApp';
import { onAnyComponentAddedToStage } from './onAnyComponentAddedToStage';
import { configureAppManager } from './appManager';
import { overrideSearchBoxGfpp } from './overrideSearchBoxGfpp';
import { reportError } from '../lib/errors';
import { removeDuplicateControllers } from './searchAppController';
import { getConnectedSearchBoxes } from './getConnectedSearchBoxes';
import { Spec } from '../lib/specs';

interface EditorPlatformApp {
  editorReady: EditorReadyFn;
  getAppManifest: GetAppManifestFn;
  handleAction: HandleActionFn;
}

export function createEditorPlatformApp(): EditorPlatformApp {
  let appContext: EditorAppContext;

  return {
    async editorReady(editorSDK, appDefinitionId, options, flowAPI) {
      const { firstInstall } = options;
      const { experiments, fedops, errorMonitor, environment, translations } =
        flowAPI;
      const { isEditorX } = environment;

      fedops.interactionStarted(Interaction.EditorReady);

      appContext = {
        flowAPI,
        translate: translations.t.bind(translations),
        editorSDK,
        appDefinitionId,
        fedops,
        experiments,
        isEditorX: environment.isEditorX,
        reportError(error) {
          reportError(errorMonitor, error, {
            firstInstall,
            isEditorX,
          });
        },
      };

      await editorSDK.addEventListener(
        EventType.anyComponentAddedToStage,
        async (event) => {
          try {
            await onAnyComponentAddedToStage(appContext, event.detail);
          } catch (e) {
            appContext.reportError(e);
          }
        },
      );

      if (firstInstall) {
        await doFirstInstall(appContext);
      } else if (experiments.enabled(Spec.AppControllerLayoutFix)) {
        await fixAppControllerLayout(appContext);
      }

      const allSearchBoxes = await getAllSearchBoxes(appContext);

      if (!isEditorX) {
        /**
         * This is a hacky way to reconnect 'abandoned' SearchBox'es (probably added by copy-pasting).
         * Investigate if it's still really needed.
         * https://jira.wixpress.com/browse/SER-1310
         */
        await reconnectSearchBoxes(appContext, allSearchBoxes);
      }

      const connectedSearchBoxes = await getConnectedSearchBoxes(appContext);

      // There is a problem where some sites have duplicated controllers for searchboxes
      // This will remove or disconnect duplicated controllers when user loads the editor
      if (
        experiments.enabled(Spec.RemoveDuplicateControllers) &&
        connectedSearchBoxes.length > allSearchBoxes.length
      ) {
        for (const componentRef of allSearchBoxes) {
          await removeDuplicateControllers(appContext, componentRef);
        }
      }

      // TODO Should we run this part of code, if none of components registered? i.e. allSearchBoxes.length === 0
      await Promise.all(
        allSearchBoxes.map((sb) => patchInputFontProperty(appContext, sb)),
      );

      fedops.interactionEnded(Interaction.EditorReady);
    },
    async getAppManifest({ appManifestBuilder }) {
      return overrideSearchBoxGfpp(appContext, appManifestBuilder)
        .configureController(ControllerType.SearchApp, (controllerBuilder) => {
          controllerBuilder.set({
            visibility: 'NEVER',
          });
        })
        .configureManagementActions((managementActionsBuilder) => {
          configureAppManager(appContext, managementActionsBuilder);
        })
        .build();
    },
    async handleAction({ type }) {
      switch (type) {
        case ActionType.RemoveApp: {
          await onRemoveApp(appContext);
          break;
        }
      }
    },
  };
}
